<template>
  <div style="background-color: #00213c">
    <div style="background-color: #41b0e7; width: 100%; height: 1rem"></div>
    <v-container style="margin-top: 4rem; margin-bottom: 5rem">
      <v-layout row wrap>
        <div
          class="footer-element"
          style="border-right: 1px solid #41b0e7; min-width: 200px"
        >
          <v-img
            contain
            max-height="100"
            :src="LogoGallagherLight"
            max-width="175"
          />
        </div>
        
        <div
          class="footer-element"
          style="border-right: 1px solid #41b0e7; min-width: 300px"
        >
          <div style="height: 100%">
            <div style="margin-bottom: 1.5rem">
              <p>Av. Apoquindo 3741 Of. 43</p>
              <p>Las Condes, Santiago</p>
            </div>

            <div style="margin-bottom: 1.5rem">
              <p>Horario de atención:</p>
              <p>L a V de 09 a 18 h. S. de 09 a 14 h</p>
            </div>

            <div style="margin-bottom: 1.5rem">
              <p>
                Teléfono:
                <a style="color: #41b0e7" href="tel:+56940810793"
                  >+56 9 4081 0793</a
                >
              </p>
            </div>

            <div style="margin-bottom: 1.5rem">
              <p>
                Email:
                <a
                  style="color: #41b0e7"
                  href="mailto:contacto@siempreseguro.cl"
                  >contacto@siempreseguro.cl</a
                >
              </p>
            </div>
          </div>
        </div>
        <div class="footer-element" style="min-width: 150px">
          <v-col class="ma-0 pa-0">
            <div style="height: 100%">Síguenos en</div>
            <v-row class="ma-0 pa-0">
              <v-btn class="ma-0" text icon color="secondary" href="https://www.instagram.com/siempresegurocl/" target="_blank">
                <v-icon>mdi-instagram</v-icon>
              </v-btn>
              <v-btn class="ma-0" text icon color="secondary" href="https://web.facebook.com/Siempre-Seguro-106609398071331" target="_blank">
                <v-icon>mdi-facebook</v-icon>
              </v-btn>
              <v-btn class="ma-0" text icon color="secondary" href="https://www.linkedin.com/company/siempre-seguro-chile/" target="_blank">
                <v-icon>mdi-linkedin</v-icon>
              </v-btn>


            </v-row>
              <br>
              Este riesgo es tomado por         
              <v-img
            contain
            max-height="100"
            :src="LogoSB"
            max-width="175"
          />
          </v-col>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import LogoGallagherLight from "@/assets/images/LogoGallagherLight.png";
import LogoSB from "@/assets/images/SB_logo.png";

export default {
  name: "Footer",
  data: () => ({
    LogoGallagherLight,
    LogoSB
  }),
};
</script>

<style lang="scss" scoped>
@import url("../styles/main.scss");

.footer-element {
  color: white;
  min-height: 200px;
  margin: 1rem;
  display: flex;
  align-items: flex-start;

  li {
    margin-bottom: 1rem;
  }

  p {
    line-height: 1;
    margin-bottom: 0.5rem;
  }
}

.layout.wrap {
  justify-content: space-around;
}
</style>
