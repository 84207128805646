<template>
  <div class="intro">
    <div class="info-container">
      <h1>Seguro mascota</h1>
      <h3>Libre elección y posibilidad de reembolso</h3>
      <br />
      <v-btn style="color:#FFFFFF; background-color: #6FACDE; " @click="$router.push('cotizador')">
        Contratar ahora
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "Intro",
};
</script>

<style lang="scss">
.intro {
  min-height: 500px;
  background-image: url("~@/assets/Intro.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;

  .info-container {
    background: rgba(0, 38, 62, 0.4);
    color: white;
    max-width: 500px;
    // max-height: 300px;
    padding: 2rem 3rem;
    line-height: 1.1;

    h1 {
      font-size: 2.5rem;
    }
    h3 {
      font-weight: normal;
      font-size: 1.5rem;
    }
  }
}
</style>